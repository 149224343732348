import styled from 'styled-components';
import { setColor } from '../../../stlyes'

const Heading = styled.div`
p{
    color:${setColor.mainWhite};
    padding:1rem 3rem;
    
}
text-align:center;

`

export default Heading  