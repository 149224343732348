import React from 'react';
import { GrDeliver } from 'react-icons/gr'
import { GiCigar } from 'react-icons/gi'
import { FaCertificate } from 'react-icons/fa'
import { GiCoffeeCup,GiCoffeeBeans } from 'react-icons/gi'


export const CoffeSectionData = [
    {
        heading: 'Изключително качество',
        paragraph: `Специализираното кафе е най-високият клас налично кафе, който следва стандартите, определени от Асоциацията за специализирано кафе (SCA). Всяка стъпка от производствения процес се следи внимателно, за да се осигури максимално качество.`,
        icon: <FaCertificate />,
    },
    {
        heading: 'Богат вкус',
        paragraph: `Всяко кафе е уникално с богати вкусове и аромати. Специализираното кафе е като виното - всяко кафе се характеризира с някои специфични дегустационни бележки, което прави преживяването при купуване и пиене на кафе далеч по-приятно.`,
        icon: <GiCoffeeCup />,
    },
    {
        heading: 'Устойчивост',
        paragraph: `Моделът за директна търговия за специализирано кафе улеснява сътрудничеството между фермерите и пекарите, което драстично повишава качеството и води до по-добър живот на местната фермерска общност.`,
        icon: <GiCoffeeBeans />,
    },

]