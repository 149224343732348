import blogPadron from '../images/blog-padron.jpg'
import blogPadron2 from '../images/blog-padron2.jpg'
import blogrturoFuente from '../images/blog-arturoFuente.png'
import blogrturoFuente2 from '../images/blog-arturoFuente24.png';
import blogOliva from '../images/blog-oliva.jpg'
import blogOliva2 from '../images/blog-oliva2.jpg';

import nubCigars from '../images/nub-cigars.jpg'
import nubCigar2 from '../images/nub-cigar2.jpg';

import cain from '../images/cain.jpg'
import cain2 from '../images/cain.png';

import laGarela from '../images/laGarela.jpg'
import LaGalera2 from '../images/LaGalera2.jpeg';

export const BlogData = [
    {
        id: 1,
        imgUrl: blogPadron,
        path: '/blog/1',
        heading: 'Историята на пурите Padron',
        paragraphOne: 'В Corojo Club, предлагаме селекция от над 200 първокласни пури на нашите членове и гости. Една от най-обичаните ни марки е Padron. Много любители на Padron обаче не знаят историята зад пурите. Ето по-отблизо историята на тези търсени пури.',
        paragraphTwo: 'Всичко започва в средата на 1800 г. с имиграцията на младо момче на име Дамасо Падрон от Куба на Канарските острови. Като остров или островитянин, младото момче е определено да работи в тютюневите полета. Семейство Падрон успява да спести малко пари, които използва за закупуване на малка ферма в региона Пинар дел Рио в Лас Обас в Куба. Те засадиха тютюн, който продаваха за 7 долара на всеки 100 лири. Семейството продължи да купува ферми из региона. Те също така закупиха фабрика в Пилото, след което семейното тютюнево предприятие беше кръстено.',
        titleTwo: 'Кой е Хосе Орландо Падрон?',
        paragraphThree: 'Хосе Орландо Падрон е роден в това семейство в Куба през 1926 г. Той е израснал близо до региона Пинар дел Рио. Въпреки че членовете на семейството му са работили собствени ферми в продължение на много години, Фидел Кастро национализира земята им през 1961 г. Това принуждава Падрон да мигрира в Испания, а след това в Ню Йорк. От Ню Йорк той се премества в Маями с глава, пълна с познания от богатия опит на семейството му в тютюневата индустрия. Падрон получаваше по 60 долара на месец като част от правителствена програма за подпомагане на кубински бежанци. Той едва се остъргваше финансово, но приятелите му подариха чук. Чук в ръка, Падрон потърси работа в дърводелския бизнес. С течение на времето той успя да спечели 600 долара, които превърна в свой собствен бизнес с пури. Този чук се превърна в символ на успехите, които ще последват Падрон.',
        imgTho: blogPadron2,
        paragraphFour: `
        Падрон наел „торцедор“, който е висококвалифициран валяк за пури, за да му помогне да излее 200 пури на ден, навити в типичния кубински стил. Той започва да произвежда нова пура, наречена Fuma, която е направена изключително от широколистни тютюни в Кънектикът. Пурата стана популярна, тъй като наподобяваше традиционните кубински пури.

        `,
        paragraphFive: `След представянето си на никарагуанския тютюн, Хосе премества бизнеса си с тютюн в тази страна. Той започва да произвежда ръчно изработени пури, като наблюдава всяка производствена стъпка, за да гарантира, че са спазени най-високите стандарти за последователност и качество. Пурите от серията Padron използват тютюн Habano, отглеждан на слънце, който е отлежал 2½ години, за да произведе среден до пълноценен вкус с много отчетлива пикантност. Пурите Padron 1964 са по-гладки и по-меки с четиригодишен тютюн. Padron 1926 използва петгодишен тютюн, за да даде изключително сложен, гладък и балансиран пушен.
        `,
        paragraphSix: ``,
        recomended: true,

    },
    {
        id: 2,
        imgUrl: blogrturoFuente,
        path: '/blog/2',
        heading: 'Кратка история на пурите на Arturo Fuente',
        paragraphOne: 'Историята на Arturo Fuente Cigars е история на борба, война и решителност, които до голяма степен са допринесли за престижа, който все още съществува зад името днес. Ако сте запален фен на тези луксозни пури, разбирането на завладяващата история зад марката ще ви накара да оцените името още повече.',
        paragraphTwo: 'Артуро Фуенте имигрира в САЩ през 1902 г., след като напуска Куба по време на испано-американската война. Той се установява в Западна Тампа, Флорида, с надеждата да рестартира живота си, след като войната е унищожила родния му град. На 24-годишна възраст той решава да отвори бизнес с пури. Годината беше 1912 и кубинските пури стават все повече и повече стока сред елитите на страната. Така Фуенте реши, че може да използва опита си с пури от родната си страна, за да създаде печеливш бизнес.',
        titleTwo: 'Историята на Фуенте е подобна на историята на други пионери на пури',
        paragraphThree: 'Историята на Фуенте е подобна на историята на други пионери на пури, които са избягали от родните си страни и са си създали имена, като са изработили ръчно възможно най-добрите пури. По времето, когато Фуенте стартира бизнеса си, неговото дървено производствено съоръжение се превръща в едно от няколкостотин в района на Тампа, което означава, че конкуренцията е ожесточена. Подобно на останалите производители, Фуенте имаше изискания си тютюн, внесен от Куба и поради състоянието на страната след войната разходите бяха изключително ниски.',
        imgTho: blogrturoFuente2,
        paragraphFour: `
        Към средата на 20-те години компанията на Фуенте излита. Пурите му се превръщаха в луксозни предмети сред богатите членове на обществото в Съединените щати и затова той включи компанията си, за да изведе печалбите си на следващото ниво. Но само за няколко години дървеният му триетажен производствен завод изгоря до основи. На Фуенте липсваха средства за възстановяване и така компанията временно беше мъртва. За да се влошат нещата, Голямата депресия настъпи скоро след това, което означава, че личните му финанси изчезнаха, докато богатата му клиентска база вече не беше в състояние да си позволи луксозни предмети като пури от висок клас.
        Едва през 1946 г., когато Фуенте най-накрая имаше средства да започне производство обратно. Досега той имаше възможността да продължи там, където е спрял, но този път той реши да управлява компанията си на малка партида в собствения си имот. Сега, живеейки в Ybor City, Флорида, той имаше място да инсталира производствено оборудване, така че пурите да могат да се търкалят по същество в задния му двор. През това време Фуенте и членовете на семейството му са участвали в производствения процес, включително съпругата му, която дотогава е станала експертна ролка за пури.

        `,
        paragraphFive: `През 1958 г. Фуенте предава бизнеса на сина си Карлос, който успява да създаде нови акаунти с дистрибутори на пури от висок клас в Ню Йорк и други богати райони в цялата страна. Бедствието обаче се случи под формата на кубинско ембарго през 1962 г. И така, Карлос премести компанията в Никарагуа, за да продължи да получава кубински тютюн. По време на Никарагуанската революция заводът беше унищожен и затова Карлос отново трябваше да премести операции, този път в Доминиканската република.

        През 80-те години компанията стартира линията Hemingways, която представя марките, известни сега на възраст доминикански тютюн. Линията беше изключително успешна и допринесе за репутацията, която марката все още носи и до днес.
        
        `,
        paragraphSix: `Днес синът на Карлос Фуенте се занимава с операции, а пурите на Артуро Фуенте произвеждат около 30 милиона пури всяка година. Предлагат се широка гама пури и марката продължава да представя нови, използвайки подписа си доминикански тютюн. Така че, следващия път, когато пушите пура Arturo Fuente, имайте предвид многото жертви, направени, за да може компанията да оцелее над 100 години.`,
        recomended: true
    },
    {
        id: 3,
        imgUrl: blogOliva,
        path: '/blog/3',
        heading: 'Oliva Cigars',
        paragraphOne: 'Историята на компанията за пури Oliva започва чак през 1886 г., когато Меланио Олива за първи път започва да отглежда тютюн в Куба. Производството на пурите е спряно временно, когато той заминава да се бие в Кубинската война за независимост. Неговите операции се възобновяват скоро след завръщането му, след което в крайна сметка сменя ръцете на сина си през 20-те години.',
        paragraphTwo: 'Времената не бяха лесни за семейство Олива, тъй като комунистите превзеха страната и принудиха семейството му да търси по-добро място за отглеждане на уникалната си комбинация от тютюн. След време в Мексико, Панама, Хондурас и Филипините, семейството му намери плодородната земя на Никарагуа и оттогава се превърна във втория по големина производител на кубински тютюн. Четиримата братя и сестри Олива все още ръководят операциите, вече с помощта на правнуците си.',
        titleTwo: 'Най-високо оценени пури Oliva',
        paragraphThree: 'Трябва само да погледнете уникално извитата глава и прецизно притиснатите ъгли, за да разберете защо марката Oliva Cigar стои като лидер на този силно конкурентен пазар. Изброена като пура номер едно на Топ 25 награди за пури за 2014 г. от Cigar Aficionado, Oliva Serie V Melanio Figurado е просто една от най-добре направените пури в света. Дизайнът и вкусът на тази пура е резултат от кулминацията на над век упорита работа и отдаденост на занаята по създаване на първокласни пури. Това беше само преди няколко десетилетия, през 1995 г., когато семейство Олива най-накрая напусна фабриката в Пласенсия и тръгна да изгражда една собствена под съкратеното име Олива. Най-големият пробив за марката Oliva Cigar беше през 2006 г., когато пуснаха Oliva Serie V. Причината, поради която мнозина в света на пурите бяха смаяни от вкуса на тази конкретна пура, беше, че семейството използва талантите на много малка група ролки за умело изработване на тази смес. Това беше мигновена сензация и издигна името на Oliva Cigars за една нощ.',
        imgTho: blogOliva2,
        paragraphFour: `
        През 2012 г. семейство Oliva Cigars реши, че е време да отдаде почит на човека, който стоеше зад началото на името преди повече от 100 години. Пурата Melanio е разработена като почит към първия производител на тютюн в това семейство и се отличава от останалите пури в марката чрез опаковката на семена Sumatra, която се отглежда специално в Еквадор. Отличителният вкус на Oliva Cigars идва с познатите големи, богати, карамелени и кафе интонации. Според Cigar Aficionado, Oliva Cigars са попаднали в този топ 25 списък невероятно девет поредни години. Това е постижение само по себе си, нещо, което е подтикнало марката към суперзвезда. Сега произвеждайки над 6 милиона пури всяка година, ентусиастите на пурите задвижват марката Oliva до втория по големина производител на тютюн в цяла Никарагуа.        `,
        paragraphFive: `През последните години семейството на пурата Oliva си партнира с Padilla, за да създаде отделен проект, наречен Studio Tobac. Компанията Oliva Cigar е договорена от Padilla за разработването на Studio Tobac Special Edition, друга популярна първокласна смесена пура.

        Cain Straight Ligero разполага с плътна, тъмна никарагуанска обвивка с три никарагуански пълнителя Ligero. Това използва листа от върха на тютюневите растения, които са изложени на пряка слънчева светлина, което води до по-тъмно, по-дебело и по-малко листо с концентриран аромат. Трите растящи региона Esteli, Condega и Jalapa също влияят на вкуса и силата на Ligero.`,
        paragraphSix: `Най-популярните от пурите на марката се произвеждат в съоръжение в Никарагуа, което може да произведе над 50 000 пури за един ден. Поради популярността на топ блендовете в тази марка и появата в топ списъците с пури година след година, увеличаването на производството не задоволява нарастващото желание за получаване на тези пури. Разширяването на марката достигна 18 страни и продължава да се увеличава всяка година. С пурките Oliva, които вече се предлагат в Канада, марката продължава да намира нова аудитория на любителите на пури всяка година.

        Дългогодишната история зад името Oliva Cigars е видяла своя дял от сътресения, но през всички тези изпитания и премеждия, компанията в основата все още има кръвта на основателя, която тече гордо, тъй като те продължават да разработват висококачествени и първокласни пури, които светът не може да се насити.`,
        recomended: true,
    },
    {
        id: 4,
        imgUrl: nubCigars,
        path: '/blog/4',
        heading: 'Nub: Наистина единствен дим',
        paragraphOne: 'Както всички новаторски продукти, пурите Nub стартираха като идея в известната фабрика Oliva в Никарагуа. Висококвалифицираните блендери в Oliva имаха достатъчно опит за пушене помежду си, за да знаят, че в повечето случаи последната трета от пурата осигурява най-добрите вкусове. Те бяха убедени, че с малко изобретателност и много проби и грешки, те могат да уловят съвършената същност на тази „последна третина“, като намалят дължината и разширят пръстеномера, за да създадат къса тлъста пура, която удря „сладкото място“ ”Скоро след като го запалите. Този експеримент бързо се осъществи, тъй като веднага след пускането на публиката на първите пури Nub, те станаха изключително популярни и промениха начина, по който се наслаждаваме на първокласни пури, изработени ръчно.',
        paragraphTwo: 'Въпреки късата си дължина, Nub гори точно толкова дълго и бавно, колкото пълноразмерния Toro или Churchill. Всъщност тази уникална форма пура успешно улавя вкуса и тялото на последните четири, пет инча от традиционната пура през целия път.',
        titleTwo: 'Nub By Oliva',
        paragraphThree: 'Изпълнена с най-добрите остарели никарагуански тютюни, внимателно култивирани от масивната ферма на Oliva, стандартната линия Nub се предлага в четири нюанса на обвивка, която включва Maduro, Кънектикът, Хабано и Камерун, като всички отлично улавят „сладкото място“ с различна сила и ексклузивни вкусови профили в зависимост от избрания стил на обвивка.',
        imgTho: nubCigar2,
        paragraphFour: `
        Последната част от поредицата, наречена Nub Nuance Seasonals, са първокласни пури в десертен стил, направени за празнуване на есенния и зимния сезон. Fall Harvest предлага топли и привлекателни вкусове на тиква, канела и подправки в сладък тютюнев профил, който ще ви върне в свежите есенни дни с всеки ароматен бутер. Winter Blend празнува мразовитите зимни месеци с топли и приветливи нотки на тъмно печено кафе, някои кожести компоненти, препечени бадеми и черта мента, за да стоплят духа.
        `,
        paragraphSix: ``,
        recomended: false,

    },
    {
        id: 5,
        imgUrl: cain,
        path: '/blog/5',
        heading: 'Blended by Oliva Cigar Company',
        paragraphOne: 'Смесено от търговския представител на Oliva Cigar Company Сам Леча, серията пури на Cain са пълни с лигаро мощности! С пълна смес от никарагуански пълнители, състояща се от листата на лигеро от региона Естели (25%), Кондега (27%) и района на долината Джалапа (30%), пурите Cain са сред най-пълните пури, налични днес.',
        paragraphTwo: 'Cain пури се предлагат по ваш избор от две добре състарени опаковки. Отглежданата в Никарагуан обвивка за семена Habano е дълбоко, червеникаво-кафяво, зъбно, мазно листо, което придава богати аромати на земя, дърво и ядки, които допълват плътния дим, произведен от сместа за пълнене на ligaro. Тези вкусове се развиват с напредването на пурата, докато финалът на пурата става по-богат и се задържа по-дълго.',
        titleTwo: 'CAIN BY OLIVA ',
        paragraphThree: 'Мексиканската обвивка на мадуро е почти черен екземпляр, изпълнен с масла, които миришат на прясно нарязано сено с нотки на какао и пръст. Опакованата с мадуро версия на пурата Cain все още е пълноценен дим, но мексиканските листа придават фина сладост, която не се среща в пурите Habano.',
        imgTho: cain2,
        paragraphFour: `
        За онези от вас, които оценяват най-пълния пушек, бяха създадени пурите от серията Cain “F”. Използвайки същата обвивка от семена Habano, отгледана в Никарагуан като оригиналните пури Cain, тази смес включва още повече никарагуанска лигаро в сместа за пълнене, създавайки това, което може би може да бъде най-пълноценната пура на пазара днес.

        `,
        paragraphFive: `
        И ако цялата тази сила не беше достатъчна, Сам Лечия направи една крачка напред пурите на Cain, като комбинира концепцията „all-ligero“ с изключителните си размери пури Nub, за да създаде Cain Nub. Пурите Nub са създадени, за да уцелят „сладкото място“ на пурата направо от бухалката, а в случая с Cain Nub, това сладко място е придружено от богат, много плътен дим с всички страхотни аромати на оригинала. Не се заблуждавайте от малкия размер на Nub, тези дебели малки пури съдържат същото количество тютюн като Чърчил и им отнема толкова време да пушат!
        
        `,
        paragraphSix: `
        Made by the Oliva Cigar Company, the Cain series cigars are handmade to the exacting standards one has come to expect from Oliva products. Excellent construction, quality leaves and outstanding blending all combine to make the Cain cigars a rich and rewarding experience for the well-heeled aficionado. Be warned, Cain cigars are not for the weak of heart!
        `,
        recomended: false
    },
    {
        id: 6,
        imgUrl: laGarela,
        path: '/blog/6  ',
        heading: 'Раждането на пурите La Galera',
        paragraphOne: 'В топъл и слънчев ден в Тамборил, считан за мнозина за световната столица на пурите, разположен в покрайнините на Сантяго, Доминиканска република, лежи Хосе Арналдо Бланко, известен на всички като „Джохи“, пушещ спокойно на пура. Човек, който е работил с тютюн откакто се помни и безусловната му любов и страст към него е очевиден за всички.',
        paragraphTwo: 'Чрез упорита работа и решителност той отглежда и преработва тютюн до съвършенство през целия си мандат като производител и производител през последните няколко десетилетия, а фабриката му Tabacalera Palma е домът на изключителни пури, спечелили любовта и признателността на пушачите навсякъде Светът.',
        titleTwo: 'Най-високо оценени пури Oliva',
        paragraphThree: 'Докато спокойно прави бавно изтегляне от оцветената си пура, подобна на карамел, той осъзнава, че всичко е точно така, както той иска. Загледан с умиление в картина през бюрото си, той оценява ролките във фабриката преди близо 80 години и усмивката започва да се отразява. Според него това е идеалният момент да съживи точно това, което гледа. Там всичко започва, La Galera е мястото, където пури се произвеждат във фабрика; там се случва магията. Тогава се раждат пурите La Galera.',
        imgTho: LaGalera2,
        paragraphFour: `
        José "Jochy" Blanco е едно от най-важните парчета от тютюневата индустрия на Доминиканската република, дългогодишният му опит в прибирането на висококачествен тютюн и създаването на премиум смеси го доведоха до един от най-успешните производители в света.
        `,
        paragraphFive: `
       
        `,
        paragraphSix: `
        
        `,
        recomended: false
    },

]