import cigar1 from '../images/cigar1.jpg'
import cigar2 from '../images/cigar2.jpg'
import cigar3 from '../images/cigar3.jpg'
import cigar4 from '../images/cigar4.jpg'




export const SliderData = [
    {
        title: 'Smoking Cigars is like falling in love. First, you are attracted by its shape; you stay for its flavor, and you must always remember never, never to let the flame go out!',
        price: '$4,280,999',
        path: '/home',
        label: 'View home',
        image: cigar1,
        alt: 'image 1'
    },
    {
        title: 'Smoking Cigars is like falling in love. First, you are attracted by its shape; you stay for its flavor, and you must always remember never, never to let the flame go out!',
        price: '$4,280,999',
        path: '/home',
        label: 'View home',
        image: cigar2,
        alt: 'image 2'
    },
    {
        title: 'Smoking Cigars is like falling in love. First, you are attracted by its shape; you stay for its flavor, and you must always remember never, never to let the flame go out!',
        price: '$4,280,999',
        path: '/home',
        label: 'View home',
        image: cigar3,
        alt: 'image 2'
    },
    {
        title: 'Smoking Cigars is like falling in love. First, you are attracted by its shape; you stay for its flavor, and you must always remember never, never to let the flame go out!',
        price: '$4,280,999',
        path: '/home',
        label: 'View home',
        image: cigar4,
        alt: 'image 4'
    }
]