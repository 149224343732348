import styled from 'styled-components'
import img__bg from '../../../images/bg_4.jpg';


const SectionInfo = styled.div`
width:100%;
min-height:100vh;
padding-top:4rem;
background-image: url(${img__bg});
`

export default SectionInfo
