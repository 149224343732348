import coffe1 from '../images/coffe1.jpg'
import coffe2 from '../images/coffe2.jpg'
import coffe3 from '../images/coffe3.png'

export const CoffeData = [
    {
        paragraphOne: 'той е изградил свят на интелигентност, опит, вкус, наука и изкуство около удоволствието от перфектната чаша кафе. Офертата на illy включва също машини за подготовка, дизайнерски чаши и аксесоари. Бизнесът се простира до illy Caffè, автентични италиански кафенета и международна мрежа от опитни баристи, носещи титлата Artisti del Gusto.',
        paragraphTwo: `llycaffè е основана през 1933 г. в Триест от Франческо Или. Днес той се води от третото поколение на семейството. Андреа Или е председател, а Масимилиано Поляни - главен изпълнителен директор.
        Ана Роси Или, съпругата на Ернесто (синът на основателя почина през 2008 г.) е почетен председател.
        Анна Или младши продължава работата на баща си и поддържа връзки с производителите на кафе.`,
        imgUrl: coffe1,
        className: false,
    },
    {
        paragraphOne: `Компанията е известна и възхитена на пет континента с висококачествения и безпогрешен кадифен вкус на кафето си, изключителна смес, съставена от девет вида чиста арабика, която всеки ден радва милиони хора у дома, офиси, хотели, ресторанти и кафенета.`,
        paragraphTwo: `Нашата мисия, страст и мания е да зарадваме всички.`,
        imgUrl: coffe2,
        className: true,
    },
    {
        paragraphOne: `Гордеем се, че сме световният референт за култура на кафе и върхови постижения. illy е компания, иновация за създаване и предлагане на най-добрите продукти в най-добрите дестинации в света, като същевременно демонстрира своя характер като лидер в сегмента на първокласното кафе.`,
        paragraphTwo: `Етика. Ние създаваме и споделяме превъзходна стойност на заинтересованите страни с течение на времето чрез нашия ангажимент за подобрение, прозрачност, устойчивост и развитие на хората.
        Превъзходство. Ние се стремим да вдъхновяваме във всички illy допирни точки в нашата стойностна верига от боб до чаша, като създаваме изключително добри и красиви преживявания и продукти, както и като работим ефективно и с чувство за спешност в стремежа си винаги да задоволяваме нуждите и желанията на нашите клиенти .`,
        imgUrl: coffe3,
        className: false,
    }
]